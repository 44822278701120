<template>
  <section
    data-vc-full-width="true"
    data-vc-full-width-init="true"
    class="vc_section bg-color-black ltx-overlay ltx-bg-overlay-bulb-main"
  >
    <div class="vc_row wpb_row vc_row-fluid">
      <div class="wpb_column vc_column_container vc_col-sm-12">
        <div class="vc_column-inner">
          <div class="wpb_wrapper">
            <div class="es-resp">
              <div class="visible-xl" style="height: 120px"></div>
              <div
                class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                style="height: 120px"
              ></div>
              <div class="visible-md" style="height: 48px"></div>
              <div class="visible-sm" style="height: 48px"></div>
              <div class="visible-ms" style="height: 48px"></div>
              <div class="visible-xs" style="height: 48px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vc_row wpb_row vc_row-fluid ltx-ba-icons-row">
      <div class="wpb_column vc_column_container vc_col-sm-9">
        <div class="vc_column-inner">
          <div class="wpb_wrapper">
            <div class="ltx-before-after">
              <div
                class="ltx-swiper-slider swiper-container ltx-before-after-sc swiper-container-fade swiper-container-initialized swiper-container-horizontal"
                id="like_sc_before_after_1839836184"
                data-space-between="30"
                data-arrows="sides"
                data-autoplay="0"
                data-autoplay-interaction=""
                data-loop=""
                data-speed="1000"
                data-effect="fade"
                data-slides-per-group="1"
                data-simulate-touch="-1"
                data-breakpoints="1;;;;;"
              >
                <div
                  class="ltx-swiper-slider swiper-container ltx-before-after-sc swiper-container-fade swiper-container-initialized swiper-container-horizontal"
                  id="like_sc_before_after_530515401"
                >
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide swiper-slide-active"
                     
                    >
                      <div class="row">
                        <div class="col-md-4 div-content">
                          <div
                            class="heading header-subheader align-left subcolor-main has-subheader heading-tag-h4"
                          >
                            <h6 class="subheader">Lau khô</h6>
                            <h4 class="header">
                              Lau khô mọi bụi bẩn bên trong xe và cốp xe
                            </h4>
                          </div>
                        
                        </div>
                        <div class="col-md-8 div-ba">
                          <div class="ltx-before-header">Trước</div>
                          <div class="ltx-wrap">
                            <div class="ltx-ba-wrap" data-filter="0">
                              <div
                                class="after item"
                                style="
                                  background-image: url(uploads/After_01.jpg);
                                "
                              ></div>
                              <div
                                class="before item"
                                style="
                                  background-image: url(uploads/Before_01.jpg);
                                "
                              ></div>
                              <img
                                src="~@/assets/images/Before_01.jpg"
                                class="ltx-ba-before"
                                alt="After/Before"
                              />
                              <div class="handle"></div>
                            </div>
                          </div>
                          <div class="ltx-after-header">Sau</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wpb_column vc_column_container vc_col-sm-3">
        <div class="vc_column-inner">
          <div class="wpb_wrapper">
            <div class="es-resp">
              <div class="visible-xl" style="height: 80px"></div>
              <div
                class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                style="height: 80px"
              ></div>
              <div class="visible-md" style="height: 32px"></div>
              <div class="visible-sm" style="height: 32px"></div>
              <div class="visible-ms" style="height: 16px"></div>
              <div class="visible-xs" style="height: 16px"></div>
            </div>
            <ul
              class="ltx-block-icon has-descr icons-count-3 align-left ltx-icon-color-main ltx-icon-size-default ltx-header-color-default ltx-icon-type-transparent ltx-bg-color-transparent layout-cols1 ltx-icon-ht-right"
              id="like_sc_block_icon_298888026"
            >
              <li>
                <div class="in">
                  <span class="ltx-icon icon-cleaner-1 bg-transparent"></span>
                  <div class="block-right">
                    <h6 class="header">Chất tẩy rửa tự nhiên</h6>
                    <div class="descr">
                      Consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore.
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="in">
                  <span class="ltx-icon icon-seat bg-transparent"></span>
                  <div class="block-right">
                    <h6 class="header">Chăm sóc ghế</h6>
                    <div class="descr">
                      Consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore.
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="in">
                  <span class="ltx-icon icon-bottle bg-transparent"></span>
                  <div class="block-right">
                    <h6 class="header">Khử mùi</h6>
                    <div class="descr">
                      Vivamus hendrerit, eros non efficitur fermentum, purus
                      lorem.
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="vc_row wpb_row vc_row-fluid">
      <div class="wpb_column vc_column_container vc_col-sm-12">
        <div class="vc_column-inner">
          <div class="wpb_wrapper">
            <div class="es-resp">
              <div class="visible-xl" style="height: 120px"></div>
              <div
                class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                style="height: 120px"
              ></div>
              <div class="visible-md" style="height: 120px"></div>
              <div class="visible-sm" style="height: 120px"></div>
              <div class="visible-ms" style="height: 120px"></div>
              <div class="visible-xs" style="height: 120px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  mounted() {
    setTimeout(() =>{
      this.initBeforeAfter();
    }, 500);
    
  },
  methods: {
    initBeforeAfter() {
      const that = this;
      if (jQuery(".ltx-before-after").length) {
      
        jQuery(".ltx-before-after .ltx-wrap").each(function () {
          var wrap = jQuery(this);

          if (!jQuery(wrap).hasClass("init")) {
            that.initBeforeAfterEl(wrap);
            jQuery(wrap).addClass("init");
          }
        });
        jQuery(".ltx-before-after .ltx-wrap .handle").on(
          "mouseover",
          function () {
            var wrap = jQuery(this).closest(".ltx-wrap");
            if (!jQuery(wrap).hasClass("init")) {
              that.initBeforeAfterEl(wrap);
              jQuery(wrap).addClass("init");
            }
          }
        );
      }
    },

    initBeforeAfterEl(el) {
      var supportsTouch =
        "ontouchstart" in window || navigator.msMaxTouchPoints;

      var $container = jQuery(el),
        $before = $container.find(".before"),
        $after = $container.find(".after"),
        $handle = $container.find(".handle"),
        $before_header = $container.parent().find(".ltx-before-header"),
        $after_header = $container.parent().find(".ltx-after-header");

      var maxX = $container.outerWidth(),
        offsetX = $container.offset().left,
        startX = 0;

      var touchstart, touchmove, touchend;
      var mousemove = function (e) {
        e.preventDefault();
        var curX = e.clientX - offsetX,
          diff = startX - curX,
          curPos = (curX / maxX) * 100;
        if (curPos > 100) {
          curPos = 100;
        }
        if (curPos < 0) {
          curPos = 0;
        }
        $before.css({ right: 100 - curPos + "%" });
        $handle.css({ left: curPos + "%" });

        $before_header.css({ opacity: 1 - (100 - curPos) / 100 });
        $after_header.css({ opacity: (100 - curPos) / 100 });
      };
      var mouseup = function (e) {
        e.preventDefault();
        if (supportsTouch) {
          jQuery(document).off("touchmove", touchmove);
          jQuery(document).off("touchend", touchend);
        } else {
          jQuery(document).off("mousemove", mousemove);
          jQuery(document).off("mouseup", mouseup);
        }
      };
      var mousedown = function (e) {
        e.preventDefault();
        startX = e.clientX - offsetX;
        if (supportsTouch) {
          jQuery(document).on("touchmove", touchmove);
          jQuery(document).on("touchend", touchend);
        } else {
          jQuery(document).on("mousemove", mousemove);
          jQuery(document).on("mouseup", mouseup);
        }
      };

      touchstart = function (e) {
        mousedown({
          preventDefault: e.preventDefault,
          clientX: e.originalEvent.changedTouches[0].pageX,
        });
      };
      touchmove = function (e) {
        mousemove({
          preventDefault: e.preventDefault,
          clientX: e.originalEvent.changedTouches[0].pageX,
        });
      };
      touchend = function (e) {
        mouseup({
          preventDefault: e.preventDefault,
          clientX: e.originalEvent.changedTouches[0].pageX,
        });
      };
      if (supportsTouch) {
        $handle.on("touchstart", touchstart);
      } else {
        $handle.on("mousedown", mousedown);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-wrapper {
  height: auto !important;
}
.ltx-before-after .ltx-after-header {
  right: 40px;
}
</style>
