<template>
  <section
    data-vc-full-width="true"
    data-vc-full-width-init="true"
    class="vc_section vc_custom_1581262803163 vc_section-has-fill bg-color-black ltx-overlay ltx-bg-overlay-bulb-white" id="ung-dung"
  >
    <!--    <div class="ltx-overlay-bulb-white"></div>-->
    <div class="vc_row wpb_row vc_row-fluid">
      <div class="wpb_column vc_column_container vc_col-sm-12">
        <div class="vc_column-inner">
          <div class="wpb_wrapper">
            <div class="es-resp">
              <div class="visible-xl" style="height: 110px"></div>
              <div
                class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                style="height: 110px"
              ></div>
              <div class="visible-md" style="height: 110px"></div>
              <div class="visible-sm" style="height: 110px"></div>
              <div class="visible-ms" style="height: 110px"></div>
              <div class="visible-xs" style="height: 110px"></div>
            </div>
            <div
              class="heading header-subheader align-center subcolor-main transform-default has-subheader theme-icon-second icon-type-hidden vc_custom_1581010220649 heading-tag-h3"
              id="like_sc_header_533572745"
            >
              <h3
                class="header ltx-sr-id-like_sc_header_5335727451393665054 ltx-sr ltx-sr-effect-fade_in ltx-sr-el-block ltx-sr-delay-0 ltx-sr-duration-1000 ltx-sr-sequences-0"
                data-sr-id="3"
              >
                Hướng dẫn đặt dịch vụ
              </h3>
            </div>
            <div
              class="ltx-content-width ltx-col-align-center ltx-block-align-center"
              id="like_sc_content_width_1071868754"
              style="max-width: 500px"
            >
              <div class="ltx-wrapper">
                <div class="wpb_text_column wpb_content_element">
                  <div class="wpb_wrapper">
                    <p style="text-align: center; color: var(--main)">
                      Thực hiện chỉ với 3 bước đơn giản
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="visible-xs" style="height: 50px"></div>
            <div class="visible-xl" style="height: 75px"></div>

            <div
              class="elementor-section elementor-inner-section elementor-element elementor-element-cd01116 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="cd01116"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-wider">
                <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-42bef3b"
                  data-id="42bef3b"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-cd7a3e4 pp-info-box-top elementor-widget elementor-widget-pp-info-box"
                      data-id="cd7a3e4"
                      data-element_type="widget"
                      data-settings='{"icon_position":"top"}'
                      data-widget_type="pp-info-box.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="pp-info-box-container">
                          <div class="pp-info-box">
                            <div class="pp-info-box-icon-wrap">
                              <span
                                class="pp-info-box-icon pp-icon elementor-animation-wobble-vertical"
                              >
                                <img src="@/assets/images/phone.svg" />
                              </span>
                            </div>
                            <div class="pp-info-box-content">
                              <div class="pp-info-box-title-wrap">
                                <div class="pp-info-box-title-container">
                                  <h4 class="pp-info-box-title">
                                    1. Yêu cầu báo giá
                                  </h4>
                                </div>
                              </div>

                              <div class="pp-info-box-divider-wrap">
                                <div class="pp-info-box-divider"></div>
                              </div>

                              <div class="pp-info-box-description">
                                Chúng tôi sẽ báo giá và hướng dẫn bạn tới ga-ra
                                gần nhất
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5275aef"
                  data-id="5275aef"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-99f5936 pp-info-box-top elementor-widget elementor-widget-pp-info-box"
                      data-id="99f5936"
                      data-element_type="widget"
                      data-settings='{"icon_position":"top"}'
                      data-widget_type="pp-info-box.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="pp-info-box-container">
                          <div class="pp-info-box">
                            <div class="pp-info-box-icon-wrap">
                              <span
                                class="pp-info-box-icon pp-icon elementor-animation-wobble-vertical"
                              >
                                <img src="@/assets/images/car_fix.svg" />
                              </span>
                            </div>
                            <div class="pp-info-box-content">
                              <div class="pp-info-box-title-wrap">
                                <div class="pp-info-box-title-container">
                                  <h4 class="pp-info-box-title">
                                    2. Nhận, Rửa và đánh bóng xe
                                  </h4>
                                </div>
                              </div>

                              <div class="pp-info-box-divider-wrap">
                                <div class="pp-info-box-divider"></div>
                              </div>

                              <div class="pp-info-box-description">
                                Bạn thực hiện thanh toán trực tiếp tại ga-ra để
                                xác nhận đơn đặt hàng.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c4b1a49"
                  data-id="c4b1a49"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-4f2b357 pp-info-box-top elementor-widget elementor-widget-pp-info-box"
                    >
                      <div class="elementor-widget-container">
                        <div class="pp-info-box-container">
                          <div class="pp-info-box">
                            <div class="pp-info-box-icon-wrap">
                              <span
                                class="pp-info-box-icon pp-icon elementor-animation-wobble-vertical"
                              >
                                <img src="@/assets/images/delivery.svg" />
                              </span>
                            </div>
                            <div class="pp-info-box-content">
                              <div class="pp-info-box-title-wrap">
                                <div class="pp-info-box-title-container">
                                  <h4 class="pp-info-box-title">
                                    3. Bàn giao xe
                                  </h4>
                                </div>
                              </div>

                              <div class="pp-info-box-divider-wrap">
                                <div class="pp-info-box-divider"></div>
                              </div>

                              <div class="pp-info-box-description">
                                Sau khi kết thúc quá trình nghiệm thu, chúng tôi
                                sẽ liên hệ với bạn để thực hiện thủ tục bàn giao
                                xe.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="visible-xs" style="height: 50px"></div>
            <div class="visible-xl" style="height: 75px"></div>
            <div class="ltx-wrapper">
              <div class="wpb_text_column wpb_content_element">
                <div class="wpb_wrapper">
                  <h6 style="text-align: center; color: #fff">Tải ứng dụng</h6>
                </div>
              </div>
               <div class="visible-xs" style="height: 30px"></div>
            <div class="visible-xl" style="height: 40px"></div>
              <div style="display: flex; align-items: center; width: 100%; justify-content: center;">
                <a href="https://iwashstudio.page.link/bjYi" target="_blank" style="cursor: pointer;"><img src="@/assets/images/download_android.svg" style="height: 80px;"></a>
                   <div class="visible-xs" style="width: 20px"></div>
            <div class="visible-xl" style="width: 40px"></div>
                <a href="https://iwashstudio.page.link/bjYi" target="_blank" style="cursor: pointer;"><img src="@/assets/images/download_ios.svg" style="height: 80px;"></a>
              </div>
            </div>

            <div class="es-resp">
              <div class="visible-xl" style="height: 110px"></div>
              <div
                class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                style="height: 110px"
              ></div>
              <div class="visible-md" style="height: 110px"></div>
              <div class="visible-sm" style="height: 110px"></div>
              <div class="visible-ms" style="height: 110px"></div>
              <div class="visible-xs" style="height: 110px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.pp-info-box-icon {
  display: block;
  margin-bottom: 20px;
}
.elementor-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  justify-content: center;
  .elementor-column {
    width: 33.333%;
    padding: 0 20px;
  }
}
.pp-info-box-divider-wrap {
  margin-bottom: 25px;
  display: flex;
  justify-content: center;

  .elementor-column-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.pp-info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pp-info-box-title {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.375em;
  margin-bottom: 15px;
}

.pp-info-box-divider {
  border-bottom-style: solid;
  width: 50px;
  border-bottom-width: 3px;
  border-bottom-color: #fff;
}

.pp-info-box-description {
  color: #ffffffb3;
}

@media (max-width: 767px) {
  .elementor-container {
    flex-direction: column;
    .elementor-column {
      width: 100%;
      margin-bottom: 50px;
    }
  }
}
</style>
