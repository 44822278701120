<template>
  <div
    data-vc-full-width="true"
    data-vc-full-width-init="true"
    data-vc-stretch-content="true"
    class="vc_row wpb_row vc_row-fluid vc_row-no-padding bg-color-black ltx-overlay ltx-bg-overlay-bulb-white displaced-top"
  >
    <div id="wrapper_top" class="wpb_column vc_column_container vc_col-sm-12" style=" position: relative; z-index: 0">
      <div class="vc_column-inner">
        <div class="wpb_wrapper">
          <div class="vc_row wpb_row vc_inner vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
              <div class="vc_column-inner">
                <div class="wpb_wrapper">
                  <div class="swiper">
                    <div
                      class="parallax-bg"
                      slot="parallax-bg"
                      data-swiper-parallax="-23%"
                    ></div>

                    <div
                      class="slide"
                      style="position: absolute; z-index: 2; color: #ffffff"
                    >
                      <div
                        class="container ltx-zs-slider-wrapper"
                        style="
                            z-index: 2;
                            position: relative;
                            min-height: 752px;
                          "
                      >
                        <div
                          class="ltx-zs-slider-inner ltx-zs-slide-3"
                          data-index="3"
                        >
                          <div class="vc_row wpb_row vc_row-fluid">
                            <div
                              class="wpb_column vc_column_container vc_col-sm-12"
                            >
                              <div class="vc_column-inner">
                                <div class="wpb_wrapper">
                                  <div class="es-resp">
                                    <div
                                      class="visible-xl"
                                      style="height: 190px"
                                    ></div>
                                    <div
                                      class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                                      style="height: 80px"
                                    ></div>
                                    <div
                                      class="visible-md"
                                      style="height: 80px"
                                    ></div>
                                    <div
                                      class="visible-sm"
                                      style="height: 80px"
                                    ></div>
                                    <div
                                      class="visible-ms"
                                      style="height: 80px"
                                    ></div>
                                    <div
                                      class="visible-xs"
                                      style="height: 80px"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="vc_row wpb_row vc_row-fluid vc_row-o-content-bottom vc_row-flex text-align-center"
                          >
                            <div
                              class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-12 vc_col-md-12"
                            >
                              <div class="vc_column-inner">
                                <div class="wpb_wrapper">
                                  <div
                                    class="heading header-subheader align-center color-white subcolor-main transform-default has-subheader theme-icon-second icon-type-hidden heading-tag-h1"
                                    id="like_sc_header_923880301"
                                  >
                                    <h6 class="subheader">
                                      THIẾT BỊ HIỆN ĐẠI
                                    </h6>
                                    <h1 class="header">
                                      Làm sạch nội và ngoại thất
                                    </h1>
                                  </div>
                                  <div class="es-resp">
                                    <div
                                      class="visible-xl"
                                      style="height: 20px"
                                    ></div>
                                    <div
                                      class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                                      style="height: 20px"
                                    ></div>
                                    <div
                                      class="visible-md"
                                      style="height: 20px"
                                    ></div>
                                    <div
                                      class="visible-sm"
                                      style="height: 20px"
                                    ></div>
                                    <div
                                      class="visible-ms"
                                      style="height: 20px"
                                    ></div>
                                    <div
                                      class="visible-xs"
                                      style="height: 20px"
                                    ></div>
                                  </div>
                                  <div
                                    class="ltx-content-width ltx-col-align-center ltx-block-align-center"
                                    id="like_sc_content_width_795253899"
                                    style="max-width: 480px"
                                  >
                                    <div class="ltx-wrapper">
                                      <div
                                        class="wpb_text_column wpb_content_element"
                                      >
                                        <div class="wpb_wrapper">
                                          <p>
                                            Đam mê của bạn là sự hài lòng của bên chúng tôi
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="es-resp">
                                    <div
                                      class="visible-xl"
                                      style="height: 48px"
                                    ></div>
                                    <div
                                      class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                                      style="height: 48px"
                                    ></div>
                                    <div
                                      class="visible-md"
                                      style="height: 48px"
                                    ></div>
                                    <div
                                      class="visible-sm"
                                      style="height: 48px"
                                    ></div>
                                    <div
                                      class="visible-ms"
                                      style="height: 48px"
                                    ></div>
                                    <div
                                      class="visible-xs"
                                      style="height: 48px"
                                    ></div>
                                  </div>
                                  <div class="btn-wrap btn-wrap-inline">
                                      <span
                                        class="ltx-btn-wrap ltx-btn-wrap-main ltx-btn-wrap-hover-white"
                                      ><a
                                        href="#bang-gia"
                                        class="btn btn-lg btn-main color-hover-white"
                                        id="like_sc_button_2107494961"
                                      ><span class="ltx-btn-inner"
                                      >Dịch vụ<span
                                        class="ltx-btn-after"
                                      ></span></span
                                      ><span
                                        class="ltx-btn-overlay ltx-btn-overlay-top"
                                      ></span
                                      ><span
                                        class="ltx-btn-overlay ltx-btn-overlay-bottom"
                                      ></span></a
                                      ></span>
                                  </div>
                                  <div
                                    class="btn-wrap align-left btn-wrap-inline"
                                  >
                                      <span
                                        class="ltx-btn-wrap ltx-btn-wrap-hover-white"
                                      ><a
                                        href="#lien-he"
                                        class="btn btn-lg btn-second color-hover-white align-left"
                                        id="like_sc_button_1175145924"
                                      ><span class="ltx-btn-inner"
                                      >Liên hệ ngay<span
                                        class="ltx-btn-after"
                                      ></span></span
                                      ><span
                                        class="ltx-btn-overlay ltx-btn-overlay-top"
                                      ></span
                                      ><span
                                        class="ltx-btn-overlay ltx-btn-overlay-bottom"
                                      ></span></a
                                      ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="vc_row wpb_row vc_row-fluid">
                            <div
                              class="wpb_column vc_column_container vc_col-sm-12"
                            >
                              <div class="vc_column-inner">
                                <div class="wpb_wrapper">
                                  <div class="es-resp">
                                    <div
                                      class="visible-xl"
                                      style="height: 330px"
                                    ></div>
                                    <div
                                      class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                                      style="height: 330px"
                                    ></div>
                                    <div
                                      class="visible-md"
                                      style="height: 250px"
                                    ></div>
                                    <div
                                      class="visible-sm"
                                      style="height: 200px"
                                    ></div>
                                    <div
                                      class="visible-ms"
                                      style="height: 260px"
                                    ></div>
                                    <div
                                      class="visible-xs"
                                      style="height: 260px"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="wrapper_bottom"
        style="
                          z-index: -1;
                          top: 0;
                          height: 100%;
                          position: absolute;
                          width: 100%;
                        "
      ></div>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/images/SLIDE_01.jpeg';
import img2 from '@/assets/images/SLIDE_02.jpeg';

import img3 from '@/assets/images/SLIDE_03.jpeg';

import img4 from '@/assets/images/SLIDE_02.jpeg';
import img5 from '@/assets/images/SLIDE_01.jpeg';


export default {
  components: {},
  data() {
    return {
      index: 0,
      images: [
        img1,
        img2,
        img3,
        img2,
        img1

      ]
    };
  },

  mounted() {
    this.changeBackground();
    setInterval(this.changeBackground, 3000);
  },
  methods: {
    changeBackground() {
      $("#wrapper_bottom").animate({opacity: 1}, 2000, () => {
        this.changeImage("#wrapper_top", this.images[this.index], 1);
        if (++this.index >= this.images.length) {
          this.index = 0;
        }
        $("#wrapper_bottom").css("opacity", 0);
        this.changeImage("#wrapper_bottom", this.images[this.index]);
      });
    },
    changeImage(id, image) {
      $(id).css("background-image", "url(" + image + ")");
    },
  },

};
</script>

<style lang="scss" scoped>
.swiper {
  height: 100vh;
  display: flex;
  justify-content: center;

}
</style>
