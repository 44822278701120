<template>
  <div>
    <div class="ltx-content-wrapper transparent-overlay">
      <div class="header-wrapper header-h1  header-parallax  wrapper-navbar-layout-transparent-overlay ltx-pageheader-disabled">
        <div id="nav-wrapper" class="navbar-layout-transparent-overlay inited">
          <nav class="navbar" data-spy="" data-offset-top="0">
            <div class="container">
              <div class="navbar-logo">
                <a class="logo" href="#">
                  <img src="~@/assets/images/logo-iwash.png" alt="iWash">
                </a>
              </div>
              <div id="navbar" class="navbar-mobile-white navbar-collapse navbar-mobile collapse" style="overflow: hidden;"
                   data-mobile-screen-width="1200">
                <div class="toggle-wrap">
                  <a class="logo" href="#">
                    <img src="~@/assets/images/logo-iwash.png" alt="iWash">
                  </a>
                  <button type="button" class="navbar-toggle" @click="toggleMenu">
                    <span class="close">×</span>
                  </button>
                  <div class="clearfix"></div>
                </div>
                <ul id="menu-main-menu" class="nav navbar-nav">
                  <li id="menu-item-13174"
                      class="menu-item menu-item-type-custom menu-item-object-custom menu-item-13174">
                    <a href="#gioi-thieu" class="scroll-smooth"><span><span>Giới thiệu</span></span></a></li>
                  <li id="menu-item-3557"
                      class="menu-item menu-item-type-custom menu-item-object-custom menu-item-3557 scroll-smooth">
                    <a href="#ung-dung" class="scroll-smooth"><span><span>Tải ứng dụng</span></span></a>
                  </li>
                  <li id="menu-item-619"
                      class="menu-item menu-item-type-custom menu-item-object-custom menu-item-619 scroll-smooth">
                    <a href="#bang-gia" class="scroll-smooth"><span><span>Bảng giá & Dịch vụ</span></span></a>
                  </li>
                  <li id="menu-item-59"
                      class="menu-item menu-item-type-custom menu-item-object-custom menu-item-59 scroll-smooth">
                    <a href="#lien-he" class="scroll-smooth"><span><span>Liên hệ</span></span></a>
                  </li>
                </ul>
              </div>
              <div class="navbar-controls" @click="toggleMenu">
                <button type="button" class="navbar-toggle collapsed">
                  <span class="icon-bar top-bar"></span>
                  <span class="icon-bar middle-bar"></span>
                  <span class="icon-bar bottom-bar"></span>
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div class="container main-wrapper">
        <!-- Content -->
        <div class="inner-page text-page margin-disabled">
          <div class="row centered">
            <div class="col-xl-12 col-xs-12 text-page">
              <article id="post-12328" class="post-12328 page type-page status-publish hentry">
                <slot />
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ltx-footer-wrapper">
      <div class="ltx-overlay-main-waves"></div>
      <div class="ltx-overlay-black-waves"></div>
      <footer class="copyright-block copyright-layout-default" style="margin-top: -72px;">
        <div class="container">
          <p>Copyright © 2022 iWash Car Studio VN</p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  components: {  },
  data() {
    return {
      title: "HOME",
      mobile: false
    };
  },

  methods: {
    toggleMenu(){
      this.mobile = !this.mobile
      document.body.style.setProperty(
        "overflow",
        this.mobile ? "hidden" : "auto"
      );
    }
  },
};
</script>

<style scoped lang="scss">

</style>
