<template>
  <section
    data-vc-full-width="true"
    data-vc-full-width-init="true"
    class="vc_section ltx-tariff-margin vc_custom_1581010070373 vc_section-has-fill displaced-top"
    id="bang-gia"
  >
    <div class="vc_row wpb_row vc_row-fluid">
      <div class="wpb_column vc_column_container vc_col-sm-12">
        <div class="vc_column-inner">
          <div class="wpb_wrapper">
            <div class="es-resp">
              <div class="visible-xl" style="height: 110px"></div>
              <div
                class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                style="height: 110px"
              ></div>
              <div class="visible-md" style="height: 110px"></div>
              <div class="visible-sm" style="height: 110px"></div>
              <div class="visible-ms" style="height: 110px"></div>
              <div class="visible-xs" style="height: 50px"></div>
            </div>
            <div
              class="heading header-subheader align-center subcolor-main transform-default has-subheader theme-icon-second icon-type-hidden vc_custom_1581010127274 heading-tag-h4"
              id="like_sc_header_1282206832"
            >
              <h4
                class="header ltx-sr-id-like_sc_header_1282206832996896873 ltx-sr ltx-sr-effect-fade_in ltx-sr-el-block ltx-sr-delay-0 ltx-sr-duration-1000 ltx-sr-sequences-0"
                data-sr-id="6"
              >
                Bảng giá
              </h4>
            </div>
          
            <div class="es-resp">
              <div class="visible-xl" style="height: 50px"></div>
              <div
                class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                style="height: 0px"
              ></div>
              <div class="visible-md" style="height: 10px"></div>
              <div class="visible-sm" style="height: 10px"></div>
              <div class="visible-ms" style="height: 10px"></div>
              <div class="visible-xs" style="height: 10px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="vc_row wpb_row vc_row-fluid"
    >
      <div class="wpb_column vc_column_container vc_col-sm-12">
        <div class="vc_column-inner">
          <div class="wpb_wrapper">
            <div class="ltx-swiper-slider-wrapper" style="display: flex">
               <img src="@/assets/images/price.jpg" style="width: 100%; margin: auto; max-width: 1200px">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vc_row-full-width vc_clearfix"></div>
    <div class="vc_row wpb_row vc_row-fluid">
      <div class="wpb_column vc_column_container vc_col-sm-12">
        <div class="vc_column-inner">
          <div class="wpb_wrapper">
            <div class="es-resp">
              <div class="visible-xl" style="height: 80px"></div>
              <div
                class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                style="height: 80px"
              ></div>
              <div class="visible-md" style="height: 80px"></div>
              <div class="visible-sm" style="height: 80px"></div>
              <div class="visible-ms" style="height: 80px"></div>
              <div class="visible-xs" style="height: 50px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      plan: 1,
    };
  },
  created() {},
  computed: {},
  methods: {
    changeCar(plan) {
      this.plan = plan;
    },
  },
};
</script>
