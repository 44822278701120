<template>
    <section data-vc-full-width="true" data-vc-full-width-init="true" class="vc_section" id="lien-he"
               >
        <div class="vc_row wpb_row vc_row-fluid">
          <div class="wpb_column vc_column_container vc_col-sm-12">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <div class="es-resp">
                  <div class="visible-xl" style="height: 140px;"></div>
                  <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                       style="height: 140px;"></div>
                  <div class="visible-md" style="height: 140px;"></div>
                  <div class="visible-sm " style="height: 140px;"></div>
                  <div class="visible-ms" style="height: 100px;"></div>
                  <div class="visible-xs" style="height: 100px;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vc_row wpb_row vc_row-fluid vc_row-o-equal-height vc_row-flex ltx-locations-row">
          <div class="wpb_column vc_column_container vc_col-sm-4 vc_hidden-md vc_hidden-sm vc_hidden-xs">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <div class="wpb_single_image wpb_content_element vc_align_center">

                  <figure class="wpb_wrapper vc_figure">
                    <div class="vc_single_image-wrapper   vc_box_border_grey"><img
                      width="431" height="386"
                      src="~@/assets/images/wash-machine.png"
                      class="vc_single_image-img attachment-full" alt=""
                      srcset="uploads/wash-machine.png 431w, uploads/wash-machine-300x269.png 300w, uploads/wash-machine-360x322.png 360w"
                      sizes="(max-width: 431px) 100vw, 431px"></div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-5 vc_col-md-6 vc_col-has-fill bg-pos-center-center">
            <div class="vc_column-inner vc_custom_1581008809939">
              <div class="wpb_wrapper">
                <div class="heading header-subheader align-left subcolor-main transform-default has-subheader theme-icon-second icon-type-hidden   vc_custom_1581008534428 heading-tag-h4"
                     id="like_sc_header_1801574357"><h6 class="subheader ">
                  Địa chỉ</h6><h4 class="header ">Địa điểm chăm sóc và rửa xe</h4>
                </div>
                <div class="es-resp">
                  <div class="visible-xl" style="height: 24px;"></div>
                  <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                       style="height: 24px;"></div>
                  <div class="visible-md" style="height: 24px;"></div>
                  <div class="visible-sm " style="height: 24px;"></div>
                  <div class="visible-ms" style="height: 24px;"></div>
                  <div class="visible-xs" style="height: 24px;"></div>
                </div>
                <ul class="ltx-block-icon  has-descr  icons-count-5 align-left ltx-icon-color-main ltx-icon-size-sm ltx-header-color-default ltx-icon-type-transparent ltx-bg-color-transparent layout-cols2 ltx-icon-ht-right  row centered"
                    id="like_sc_block_icon_2056301211">
                  <li class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-ms-6 col-xs-12" @click="changeLocation(0)">
                    <div class="in">
                      <span class="ltx-icon fas fa-map-marker-alt bg-transparent"></span></div>
                    <div href="#" class="block-right" :class="{ 'block-right-active' : location === 0}">
                      <strong class="ltx-header"> Cơ sở 1 </strong>
                    <div class="descr">281 Đội cấn, Ba Đình, Hà Nội</div>
                    </div>
                  </li>
                  <li class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-ms-6 col-xs-12" @click="changeLocation(1)">
                    <div class="in">
                      <span class="ltx-icon fas fa-map-marker-alt bg-transparent"></span></div>
                    <div href="#" class="block-right" :class="{ 'block-right-active' : location === 1}">
                      <strong class="ltx-header"> Cơ sở 2 </strong>
                    <div class="descr">1031 Bạch Đằng, Hai Bà Trưng, Hà Nội</div>
                    </div>
                  </li>
                </ul>
                <div class="es-resp">
                  <div class="visible-xl" style="height: 0px;"></div>
                  <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                       style="height: 0px;"></div>
                  <div class="visible-md" style="height: 0px;"></div>
                  <div class="visible-sm " style="height: 64px;"></div>
                  <div class="visible-ms" style="height: 64px;"></div>
                  <div class="visible-xs" style="height: 64px;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-6">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <div class="ltx-contact-form-7 transform- form-center form-black form-bg-black form-style- form-btn- form-btn- form-padding-default"
                     style="background-image: url(uploads/carbon_BG-20.png);"
                     id="like_sc_contact_form_7_1311885518">
                  <h5 class="ltx-header">Đặt lịch <span> rửa xe </span></h5>
                  <div role="form" class="wpcf7" id="wpcf7-f11005-p12328-o1" lang="en-US" dir="ltr">
                    <div class="screen-reader-response"></div>
                    <form class="wpcf7-form" novalidate="novalidate" @submit.prevent="sendForm">
                      <p>
                        <span class="wpcf7-form-control-wrap your-name">
                          <input autocomplete="off" type="text" name="your-name" v-model="name" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Họ tên">
                          <span v-show="errorName" role="alert" class="wpcf7-not-valid-tip">Vui lòng nhập họ tên.</span>
                        </span>
                        <br>
                        <span class="wpcf7-form-control-wrap your-phone">
                          <input autocomplete="off" type="text" name="your-phone" v-model="phone" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Số điện thoại">
                          <span v-show="errorPhone" role="alert" class="wpcf7-not-valid-tip">Vui lòng nhập số điện thoại.</span>
                          <span v-show="!errorPhone && errorPhoneFormat" role="alert" class="wpcf7-not-valid-tip">Vui lòng nhập đúng định dạng số điện thoại.</span>
                        </span>
                        <br>
                        <span class="wpcf7-form-control-wrap your-time">
                          <div class="select-wrap">
                            <select v-model="time" class="wpcf7-form-control wpcf7-select" aria-invalid="false">
                              <option>07:00</option>
                              <option>08:00</option>
                              <option>09:00</option>
                              <option>10:00</option>
                              <option>11:00</option>
                              <option>12:00</option>
                              <option>13:00</option>
                              <option>14:00</option>
                              <option>15:00</option>
                              <option>16:00</option>
                              <option>17:00</option>
                              <option>18:00</option>
                              <option>19:00</option>
                              <option>20:00</option>
                            </select>
                          </div>
                        </span>
                        <br>
                        <span class="ltx-btn-wrap">
                          <span class="ltx-btn-wrap">
                            <input type="submit" value="Đặt lịch" class="wpcf7-form-control wpcf7-submit btn">
                          </span>
                        </span>
                        <span class="ajax-loader" :class="{'is-active': loading}"></span>
                      </p>
                      <div v-show="msg" class="wpcf7-response-output wpcf7-validation-errors">
                          {{ msg }}
                      </div>
                      <div v-show="successContact" class="wpcf7-response-output wpcf7-mail-sent-ok" style="color: white;" role="alert">Cảm ơn bạn đã đặt lịch, chúng tôi đã nhận được tin nhắn.</div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vc_row wpb_row vc_row-fluid">
          <div class="wpb_column vc_column_container vc_col-sm-12">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <div class="es-resp">
                  <div class="visible-xl" style="height: 130px;"></div>
                  <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                       style="height: 130px;"></div>
                  <div class="visible-md" style="height: 130px;"></div>
                  <div class="visible-sm " style="height: 130px;"></div>
                  <div class="visible-ms" style="height: 100px;"></div>
                  <div class="visible-xs" style="height: 100px;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
</template>

<script>
export default {
  data(){
    return {
      location: null,
      name: null,
      phone: null,
      time: '07:00',
      loading: false,
      address: [
        '281 Đội cấn, Ba Đình, Hà Nội',
        '1031 Bạch Đằng, Hai Bà Trưng, Hà Nội'
      ],
      msg: '',
      errorName: false,
      errorPhone: false,
      errorPhoneFormat: false,
      successContact: false
    }
  },
  methods: {
    changeLocation(location){
      this.location = location
    },
    async sendForm(){
      //validate
      let validate = true
      if(this.location === null){
        this.msg = 'Lỗi: Vui lòng chọn địa chỉ!'
        validate = false
      }else{
        this.msg = ''
      }

      if(this.name === null || this.name === ''){
        this.errorName = true
        validate = false
      }else{
        this.errorName = false
      }

      if(this.phone === null || this.phone === ''){
        this.errorPhone = true
        validate = false
      }else{
        this.errorPhone = false
      }

      if(/^(09|08|03|07|05|04)(\d{8})$/.test(this.phone) === false){
        this.errorPhoneFormat = true
        validate = false
      }else{
        this.errorPhoneFormat = false
      }

      if(!validate){
        return
      }

      let address = this.address[this.location]
      this.loading = true;
      let payload = {
        name: this.name,
        phone: this.phone,
        time: this.time,
        address
      }
      try{
        let resp = await this.$store.dispatch("contact/booking", payload);
        this.errorName = false
        this.errorPhone = false
        this.errorPhoneFormat = false
        this.successContact = true
      } catch (e){
        console.log(e.responseJSON)
      }
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.block-right{
  border: 1px solid transparent;
  padding: 5px 10px 5px 40px !important;
  border-radius: 5px;
  cursor: pointer;
}
.ltx-icon{
  left: 10px !important;
  top: 7px !important;
}
.ltx-block-icon.ltx-icon-ht-left.ltx-icon-size-sm.ltx-icon-ht-right li:hover .block-right,
.ltx-block-icon.ltx-icon-ht-right.ltx-icon-size-sm.ltx-icon-ht-right li:hover .block-right, {
  border: 1px solid var(--main);
  border-radius: 5px;
}
.block-right-active{
  border: 1px solid var(--main);
  border-radius: 5px;
}
.d-none{
  display: none;
}
.error-phone, .error-name{
  display: block;
}
</style>
