<template>
  <default-layout>
    <div class="entry-content clearfix" id="entry-div">
      <slider-banner></slider-banner>
      <div class="vc_row-full-width vc_clearfix"></div>
      <section data-vc-full-width="true" data-vc-full-width-init="true"
               class="vc_section bg-pos-right-center text-align-center-sm-ms"
               >
        <div class="vc_row wpb_row vc_row-fluid">
          <div class="wpb_column vc_column_container vc_col-sm-12">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <div class="es-resp">
                  <div class="visible-xl" style="height: 90px;"></div>
                  <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                       style="height: 90px;"></div>
                  <div class="visible-md" style="height: 48px;"></div>
                  <div class="visible-sm " style="height: 48px;"></div>
                  <div class="visible-ms" style="height: 16px;"></div>
                  <div class="visible-xs" style="height: 16px;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vc_row wpb_row vc_row-fluid bg-pos-right-center" id="gioi-thieu">
          <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-5 vc_col-md-6">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <div class="es-resp">
                  <div class="visible-xl" style="height: 70px;"></div>
                  <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                       style="height: 70px;"></div>
                  <div class="visible-md" style="height: 70px;"></div>
                  <div class="visible-sm " style="height: 70px;"></div>
                  <div class="visible-ms" style="height: 70px;"></div>
                  <div class="visible-xs" style="height: 70px;"></div>
                </div>
                <div class="ltx-content-width ltx-col-align-left ltx-block-align-left"
                     id="like_sc_content_width_612856097" style="max-width: 500px">
                  <div class="ltx-wrapper">
                    <div class="heading header-subheader align-left subcolor-main transform-default has-subheader theme-icon-second icon-type-hidden   vc_custom_1581342964635 heading-tag-h4"
                         id="like_sc_header_907633274"><h6 class="subheader ">Thiết bị hiện đại</h6><h4 class="header ">Rửa và làm sạch xe chuyên nghiệp </h4></div>
                    <div class="wpb_text_column wpb_content_element ">
                      <div class="wpb_wrapper">
                        <p>Phasellus in arcu dapibus, lobortis est in, suscipit
                          diam. Vivamus faucibus faucibus eros et porttitor.
                          Sed est nulla, tincidunt ac ex eget, dictum mollis
                          tortor. Vivamus faucibus nec ipsum id aliquam
                          lobortis est.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="es-resp">
                  <div class="visible-xl" style="height: 40px;"></div>
                  <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                       style="height: 40px;"></div>
                  <div class="visible-md" style="height: 40px;"></div>
                  <div class="visible-sm " style="height: 40px;"></div>
                  <div class="visible-ms" style="height: 40px;"></div>
                  <div class="visible-xs" style="height: 40px;"></div>
                </div>
                <div class="heading default align-left subcolor-main transform-default icon-type-hidden   vc_custom_1580831737703 heading-tag-h6"
                     id="like_sc_header_1643919738">
                  <h6 class="header ">Hotline: <span> (024) 38 212 212 </span></h6>
                </div>
                <div class="es-resp">
                  <div class="visible-xl" style="height: 50px;"></div>
                  <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                       style="height: 50px;"></div>
                  <div class="visible-md" style="height: 50px;"></div>
                  <div class="visible-sm " style="height: 50px;"></div>
                  <div class="visible-ms" style="height: 50px;"></div>
                  <div class="visible-xs" style="height: 50px;"></div>
                </div>
                <div class="btn-wrap align-left">
                                                    <span class="ltx-btn-wrap ltx-btn-wrap-main"><a href="tel:038212212"
                                                                                                    class="btn  btn-lg btn-main color-hover-default align-left btn-call-action"
                                                                                                    id="like_sc_button_566061939"><span
                                                      class="ltx-btn-inner">Gọi ngay<span
                                                      class="ltx-btn-after"></span></span><span
                                                      class="ltx-btn-overlay ltx-btn-overlay-top"></span><span
                                                      class="ltx-btn-overlay ltx-btn-overlay-bottom"></span></a></span>
                </div>
              </div>
            </div>
          </div>
          <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-7 vc_col-md-6 vc_hidden-sm vc_hidden-xs">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <div class="wpb_single_image wpb_content_element vc_align_left   ltx-car-wash">

                  <figure class="wpb_wrapper vc_figure">
                    <div class="vc_single_image-wrapper   vc_box_border_grey"><img
                      width="1600" height="710"
                      src="~@/assets/images/washer.png"
                      class="vc_single_image-img attachment-full" alt=""
                      srcset="~@/assets/images/washer.png 1600w, ~@/assets/images/washer-300x133.png 300w, ~@/assets/images/washer-768x341.png 768w, ~@/assets/images/washer-1024x454.png 1024w, ~@/assets/images/washer-1536x682.png 1536w, ~@/assets/images/washer-360x160.png 360w, ~@/assets/images/washer-600x266.png 600w, ~@/assets/images/washer-800x355.png 800w, ~@/assets/images/washer-550x244.png 550w"
                      sizes="(max-width: 1600px) 100vw, 1600px"></div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vc_row wpb_row vc_row-fluid">
          <div class="wpb_column vc_column_container vc_col-sm-12">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <div class="es-resp">
                  <div class="visible-xl" style="height: 165px;"></div>
                  <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                       style="height: 165px;"></div>
                  <div class="visible-md" style="height: 85px;"></div>
                  <div class="visible-sm " style="height: 85px;"></div>
                  <div class="visible-ms" style="height: 48px;"></div>
                  <div class="visible-xs" style="height: 48px;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="vc_row-full-width vc_clearfix"></div>
      <section data-vc-full-width="true" data-vc-full-width-init="true" class="vc_section">
        <div class="vc_row wpb_row vc_row-fluid">
          <div class="wpb_column vc_column_container vc_col-sm-12">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <ul class="ltx-block-icon  has-descr  icons-count-4 align-left ltx-icon-color-main ltx-icon-size-lg ltx-header-color-default ltx-icon-type-transparent ltx-bg-color-transparent layout-cols4 ltx-icon-top  row centered ltx-icon-div-enabled"
                    id="like_sc_block_icon_2043364770">
                  <li class=" col-lg-4 col-md-4 col-sm-6 col-ms-6 col-xs-6">
                    <div class="in"><span
                      class="ltx-icon icon-car-wash bg-transparent"></span><h6
                      class="header"> Rửa xe </h6>
                      <div class="descr">Rửa xe siêu sạch bằng nước khử Ion, không để lại cặn.
                      </div>
                    </div>
                  </li>
                  <li class=" col-lg-4 col-md-4 col-sm-6 col-ms-6 col-xs-6">
                    <div class="in"><span
                      class="ltx-icon icon-cleaner bg-transparent"></span> <h6
                      class="header"> Vật liệu an toàn </h6>
                      <div class="descr">Sản phẩm chăm sóc chuyên dụng cao cấp theo đúng hàm lượng, không pha tạp.
                      </div>
                    </div>
                  </li>
                  <li class=" col-lg-4 col-md-4 col-sm-12 col-ms-12 col-xs-12">
                    <div class="in"><span
                      class="ltx-icon icon-care bg-transparent"></span> <h6
                      class="header"> Dịch vụ </h6>
                      <div class="descr">Gói chăm sóc 5  <span class="fa fa-star star-checked"></span>, tiêu chuẩn AMMO NYC nổi tiếng hàng đầu thế giới, chuyển chăm sóc siêu xe triệu USD.
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="es-resp">
                  <div class="visible-xl" style="height: 120px;"></div>
                  <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                       style="height: 120px;"></div>
                  <div class="visible-md" style="height: 64px;"></div>
                  <div class="visible-sm " style="height: 64px;"></div>
                  <div class="visible-ms" style="height: 64px;"></div>
                  <div class="visible-xs" style="height: 64px;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="vc_row-full-width vc_clearfix"></div>
      <slider-service></slider-service>
      <div class="vc_row-full-width vc_clearfix"></div>
      <plan></plan>
      <div class="vc_row-full-width vc_clearfix"></div>
      <before-after></before-after>
      <div class="vc_row-full-width vc_clearfix"></div>
<!--      <section data-vc-full-width="true" data-vc-full-width-init="true"
               class="vc_section bg-color-theme_color text-align-center-sm-ms"
               >
        <div class="vc_row wpb_row vc_row-fluid vc_custom_1581009445589 vc_row-o-equal-height vc_row-o-content-middle vc_row-flex">
          <div class="wpb_column vc_column_container vc_col-sm-4">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <div class="heading default align-left color-black subcolor-white transform-default icon-type-hidden   vc_custom_1581009363372 heading-tag-h5"
                     id="like_sc_header_1534064496"><h5 class="header ">Hurry to join
                  the <span> discount program </span></h5></div>
                <div class="es-resp">
                  <div class="visible-xl" style="height: 0px;"></div>
                  <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                       style="height: 0px;"></div>
                  <div class="visible-md" style="height: 0px;"></div>
                  <div class="visible-sm " style="height: 0px;"></div>
                  <div class="visible-ms" style="height: 24px;"></div>
                  <div class="visible-xs" style="height: 24px;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="wpb_column vc_column_container vc_col-sm-5">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <div class="wpb_single_image wpb_content_element vc_align_center   ltx-discount-card ltx-discount-card-1 ltx-sr ltx-sr-effect-slide_from_bottom  ltx-sr-id-1814946762  ltx-sr-el-block  ltx-sr-delay-500  ltx-sr-duration-300  ltx-sr-sequences-100"
                     data-sr-id="8"
                     style="visibility: visible; transform: translateY(0px) scale(1); opacity: 1; transition: all 0.3s ease 0s;">

                  <figure class="wpb_wrapper vc_figure">
                    <a href="#" target="_self"
                       class="vc_single_image-wrapper   vc_box_border_grey"><img
                      width="448" height="395"
                      src="http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_01.png"
                      class="vc_single_image-img attachment-full" alt=""
                      srcset="http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_01.png 448w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_01-300x265.png 300w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_01-360x317.png 360w"
                      sizes="(max-width: 448px) 100vw, 448px"></a>
                  </figure>
                </div>

                <div class="wpb_single_image wpb_content_element vc_align_center   ltx-discount-card ltx-discount-card-2 ltx-sr ltx-sr-effect-slide_from_bottom  ltx-sr-id-1582738354  ltx-sr-el-block  ltx-sr-delay-200  ltx-sr-duration-300  ltx-sr-sequences-100"
                     data-sr-id="10"
                     style="visibility: visible; transform: translateY(0px) scale(1); opacity: 1; transition: all 0.3s ease 0s;">

                  <figure class="wpb_wrapper vc_figure">
                    <a href="#" target="_self"
                       class="vc_single_image-wrapper   vc_box_border_grey"><img
                      width="436" height="436"
                      src="http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02.png"
                      class="vc_single_image-img attachment-full" alt=""
                      srcset="http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02.png 436w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02-150x150.png 150w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02-300x300.png 300w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02-360x360.png 360w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02-100x100.png 100w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02-24x24.png 24w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02-48x48.png 48w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02-96x96.png 96w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02-250x250.png 250w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02-140x140.png 140w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02-110x110.png 110w, http://aqualine.like-themes.com/wp-content/uploads/2018/02/card_02-275x275.png 275w"
                      sizes="(max-width: 436px) 100vw, 436px"></a>
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div class="wpb_column vc_column_container vc_col-sm-3">
            <div class="vc_column-inner">
              <div class="wpb_wrapper">
                <div class="btn-wrap align-center">
                                                    <span class="ltx-btn-wrap ltx-btn-wrap-black ltx-btn-wrap-hover-white"><a
                                                      href=""
                                                      class="btn  btn-lg btn-black color-hover-white align-center"
                                                      id="like_sc_button_707914775"><span class="ltx-btn-inner">Join now<span
                                                      class="ltx-btn-after"></span></span><span
                                                      class="ltx-btn-overlay ltx-btn-overlay-top"></span><span
                                                      class="ltx-btn-overlay ltx-btn-overlay-bottom"></span></a></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>-->
      <div class="vc_row-full-width vc_clearfix"></div>
      <div data-vc-full-width="true" data-vc-full-width-init="true"
           class="vc_row wpb_row vc_row-fluid bg-color-gray"
           >
        <div class="wpb_column vc_column_container vc_col-sm-12">
          <div class="vc_column-inner">
            <div class="wpb_wrapper">
              <div class="es-resp">
                <div class="visible-xl" style="height: 100px;"></div>
                <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                     style="height: 100px;"></div>
                <div class="visible-md" style="height: 100px;"></div>
                <div class="visible-sm " style="height: 100px;"></div>
                <div class="visible-ms" style="height: 64px;"></div>
                <div class="visible-xs" style="height: 64px;"></div>
              </div>
              <div class="ltx-partners ltx-hover-logos  layout-simple ltx-logos-6"
                   id="like_sc_header_1497948325">
                <div class="row centered">
                  <div class=" col-md-2  col-ms-6 col-xs-6 col-sm-4  partners-wrap  center-flex">
                    <div class="partners-item item center-flex"><a href="#"><img
                      src="~@/assets/images/partners-hs-01.png"
                      class="image" alt="."><img
                      src="~@/assets/images/partners-hs-01.png"
                      class="image ltx-img-hover" alt="."></a></div>
                  </div>
                  <div class=" col-md-2  col-ms-6 col-xs-6 col-sm-4  partners-wrap  center-flex">
                    <div class="partners-item item center-flex"><a href="#"><img
                      src="~@/assets/images/partners-hs-02.png"
                      class="image" alt="."><img
                      src="~@/assets/images/partners-hs-02.png"
                      class="image ltx-img-hover" alt="."></a></div>
                  </div>
                  <div class=" col-md-2  col-ms-6 col-xs-6 col-sm-4  partners-wrap  center-flex">
                    <div class="partners-item item center-flex"><a href="#"><img
                      src="~@/assets/images/partners-hs-03.png"
                      class="image" alt="."><img
                      src="~@/assets/images/partners-hs-03.png"
                      class="image ltx-img-hover" alt="."></a></div>
                  </div>
                  <div class=" col-md-2  col-ms-6 col-xs-6 col-sm-4  partners-wrap  center-flex">
                    <div class="partners-item item center-flex"><a href="#"><img
                      src="~@/assets/images/partners-hs-04.png"
                      class="image" alt="."><img
                      src="~@/assets/images/partners-hs-04.png"
                      class="image ltx-img-hover" alt="."></a></div>
                  </div>
                  <div class=" col-md-2  col-ms-6 col-xs-6 col-sm-4  partners-wrap  center-flex">
                    <div class="partners-item item center-flex"><a href="#"><img
                      src="~@/assets/images/partners-hs-05.png"
                      class="image" alt="."><img
                      src="~@/assets/images/partners-hs-05.png"
                      class="image ltx-img-hover" alt="."></a></div>
                  </div>
                  <div class=" col-md-2  col-ms-6 col-xs-6 col-sm-4  partners-wrap  center-flex">
                    <div class="partners-item item center-flex"><a href="#"><img
                      src="~@/assets/images/partners-hs-06.png"
                      class="image" alt="."><img
                      src="~@/assets/images/partners-hs-06.png"
                      class="image ltx-img-hover" alt="."></a></div>
                  </div>
                </div>
              </div>
              <div class="es-resp">
                <div class="visible-xl" style="height: 100px;"></div>
                <div class="hidden-xl hidden-md hidden-sm hidden-ms hidden-xs"
                     style="height: 100px;"></div>
                <div class="visible-md" style="height: 100px;"></div>
                <div class="visible-sm " style="height: 100px;"></div>
                <div class="visible-ms" style="height: 64px;"></div>
                <div class="visible-xs" style="height: 64px;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vc_row-full-width vc_clearfix"></div>
      <!-- <product></product>
        <div class="vc_row-full-width vc_clearfix"></div> -->
      <location></location>
      <div class="vc_row-full-width vc_clearfix"></div>
    </div>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
import SliderBanner from "@/router/pages/home/sliderBanner";
import SliderService from "@/router/pages/home/sliderService";
import Plan from "@/router/pages/home/plan";
import BeforeAfter from "@/router/pages/home/beforeAfter";
import Feedback from "@/router/pages/home/feedback";
import Product from "@/router/pages/home/product";
import Location from './location.vue';

export default {
  components: {Product, Feedback, BeforeAfter, Plan, SliderService, SliderBanner, DefaultLayout, Location},
  data() {
    return {
    };
  },
  created() {
  },
  computed: {},
  methods: {

  }
};
</script>

<style lang="scss" scoped>
  .btn-call-action{
    color: white;
  }
  .star-checked {
    color: orange;
  }

</style>
